.App {
  text-align: center;
  color: #838CA3;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-image: url("../public/BE.gif"); */
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.connectbutton{
  right: 50px;
  top:20px
}

.wallet{
  left: 50px;
  top:20px
}

.App-header1 {
  margin-top: 50px;
  width: 100%;
  background-color: rgba(213, 218, 208, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-header2 {
  margin-top: 200px;
  width: 100%;
  background-color: rgba(213, 218, 208, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zindex{
  z-index: 100;
  display: flex;
  flex-direction: row-reverse;
}

.floating{
  display: flex;
  justify-content: center;
  flex-direction: column;
  border:solid gray 1px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 50px;
  left: 50px;
}
.size{
  width: 90;
  height: 90;
}
.h30{
  height: 30px;
}
.arrow:hover{
 scale: 1.2;
}
.arrow:active{
  scale: 1;
 }

 .smallicon{
  width: 50px;
  height:50px
 }
 .walletconnecttopbutton{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
 }
 .walletconnecttopbutton:hover{
  background-color: #8f8a8a;
 }
 .walletconnectmidbutton:hover{
  background-color: #8f8a8a;
 }
 .walletconnectbotttombutton:hover{
  background-color: #8f8a8a;
 }
 .walletconnectbotttombutton{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
 }
 .userimage{
  width: 40px;
  height: 40px;
  border-radius: 50%;
 }
 .dropdown-toggle::after{
  display: none;
 }
 .text-left{
  text-align: left !important;
 }
 .walleticon{
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  font-size: 15px;
}
.walleticon:hover{
  font-size: 16px;
}
.editicon{
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  font-size: 17px;
}

.ptop75{
  padding-top: 80px;
}

.editicon:hover{
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .App-header1 {
    margin-top: 50px;
    width: 100%;
    background-color: rgba(213, 218, 208, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .connectbutton{
    right: 20px;
    top:10px
  }
  .wallet{
    left: 20px;
    top:10px
  }
  .h1-color{
    font-size: 50px !important;
  }
  .acccountText{
    left: 20px !important
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'MyWebFont';
  src: url('../public/JosefinSans-Thin.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../public/JosefinSans-Thin.woff') format('woff');
}

.imagecard{
  border-radius: 5px;
  border: solid rgba(167, 161, 161, 0.521) 1px;
  width: 210px !important;
  height: 260px !important;
  margin: 5px;
  background-color: #F5F5F5;
}

.round{
  padding-top: 10px;
  background-position: center;
  background-size: cover;
  background-image: url('../public/circle.svg');
  margin-top: 2px;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 10x !important;
  border-radius: 50%;
  width:47px ;
  height:47px;
}

.roundedbutton{
  border-radius: 10px;
  padding-top: 8px;
  margin-top: 2px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 2px;
  width:45px ;
  height:45px;
  background-image: url('../public/Rectangle.svg');
  background-position: center;
  background-size: cover;
}

.roundedbutton:hover{
  border:solid rgba(66, 66, 4, 0.562) 1px;
}

.h1-color{
  color: #838CA3;
  font-family: MyWebFont;
  font-size: 72px;
  margin-top: 10px;
}
.image{
  width: 208px;
  height: 210px;
}

body{
  overflow: hidden;
}

.image:hover{
  transform: scale(1.05);
  box-shadow: 0 3px 5px rgba(15, 5, 4, 0.699), 0 3px 5px rgba(15, 5, 4,  0.7);
}

.lefttext{
  margin-left: 10px;
  text-align: left;
}

.buttonbox{
  margin-top: 3px;
  margin-bottom: 3px;
}

.button{
  background-image: url('../public/activebutton.svg');
  background-position: center;
  background-size: cover;
  width:110px ;
  height:30px;
  margin-top: 10px;
}
.centerelement{
  display: flex;
  justify-content: center;
}

.button:active{
  padding-top: 2px;
  background-image: url('../public/button1.svg');
}

.acccountText{
  font-size: larger;
  position :absolute;
  top: 100px;
  left: 70px
}

.searchBox{
  font-size: larger;
  position :absolute;
  top: 100px;
  right: 150px
}

.splitter-layout{
  padding-top: 75px;
}

.layout-pane{
  display: flex;
  justify-content: center;
}

.line{
  width: 100%;
  position: absolute;
  top: 59px;
}

.historybar{
  width: 60%;
  display: flex;
}

.historycard{
  height: 70px;
  width: 70px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
